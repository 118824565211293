<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <div>
        <el-input v-model="searchForm.userName" placeholder="请输入用户名" class="mgB10 custom-form-input"></el-input>
        <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
        </el-button>
        <el-button type="success" @click="add" plain class="mgL10 mgB10">添加
        </el-button>
        <!-- 表格 -->
        <el-table :data="tableData" border v-loading="loading">
          <el-table-column prop="userName" :show-overflow-tooltip="true" header-align="center" align="center"
            label="用户名"></el-table-column>
          <el-table-column prop="userLogin" :show-overflow-tooltip="true" header-align="center" align="center"
            label="登录名"></el-table-column>
          <el-table-column prop="roleName" :show-overflow-tooltip="true" header-align="center" align="center"
            label="角色"></el-table-column>
          <el-table-column prop="userPhone" :show-overflow-tooltip="true" header-align="center" align="center"
            label="手机号"></el-table-column>
          <el-table-column prop="companyName" :show-overflow-tooltip="true" header-align="center" align="center"
            label="所属公司"></el-table-column>
          <el-table-column prop="enable" header-align="center" align="center" label="是否启用" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enable" @change="changeState(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
                编辑
              </el-button>
              <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
        </table-page>
      </div>
    </el-card>
    <!-- 用户 编辑  添加-->
    <el-dialog class="custom-dialog" :title="'用户'+(formType?'编辑':'添加')" :visible.sync="dialogVisible" width="520px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="formRules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名" prop="userName" class="custom-form-item">
              <el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="登录名" prop="userLogin" class="custom-form-item">
              <el-input v-model="form.userLogin" placeholder="请输入登录名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色" prop="userRole" class="custom-form-item">
              <el-cascader :options="roleOptions" v-model="form.userRole" :props="{ checkStrictly: true,label:'title'}"
                @change="cascaderChange" clearable>
              </el-cascader>
              <!-- <el-input v-model="form.userRole" placeholder="请输入选择角色"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="userPhone" class="custom-form-item">
              <el-input v-model="form.userPhone" type="number" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="!formType">
            <el-form-item label="密码" prop="password" class="custom-form-item">
              <el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!formType">
            <el-form-item label="确认密码" prop="repass" class="custom-form-item">
              <el-input v-model="form.repass" type="password" placeholder="请输入确认密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formType" prop="passwords">
            <el-form-item label="密码">
              <el-input value="*******" disabled type="password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "systemUser",
    filters: {

    },
    data() {
      let than = this;
      return {
        searchForm: {
          userName: "",
          page: 1,
          limit: 10,
        },
        loading: false,
        tableData: [],
        total: 0,
        dialogVisible: false,
        formType: false,
        loadingAlert: false,
        roleOptions: [],
        form: {
          userLogin: "",
          userName: "",
          userRole: "",
          userPhone: "",
          password: "",
          repass: "",
          id: ""
        },
        formRules: {
          userLogin: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          userName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          userRole: [{
            required: true,
            message: '不可为空',
            trigger: 'change'
          }],
          userPhone: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (!this.$util.isPoneAvailable(value)) {
                callback(new Error('请输入正确的手机号码!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          passwords: [{
            required: false,
            message: '不可为空',
            trigger: 'blur'
          }],
          repass: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (than.form.password !== value) {
                callback(new Error('两次输入的密码不一致!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }]
        }
      }
    },
    mounted() {
      this.search();
      this.getRoleTree()
    },
    methods: {
      //角色选择更改
      cascaderChange(e) {
        console.log(e)
      },
      //获取测温数据
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.userListPage, {
          userName: this.searchForm.userName,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      //搜索
      search() {
        this.getData(1, this.searchForm.limit)
      },
      //分页
      feach(e) {
        this.getData(e.page, e.limit)
      },
      //添加
      add() {
        this.dialogVisible = true;
      },
      //编辑表格数据
      editRow: function(row) {
        this.formType = true;
        this.dialogVisible = true;
        let data = JSON.parse(JSON.stringify(row))
        var userRoles = {}
        this.findRoleIds(this.roleOptions, data.userRole, userRoles)
        let codes = userRoles.codes.substring(1, userRoles.codes.length - 1).split("],[")
        codes.splice(0, 1)
        let ids=[]
        codes.forEach(item=>{
          this.findRoleCodes(this.roleOptions,item,ids)
        })
        ids.push(userRoles.id)
        data["userRole"] = ids
        this.form = data
      },
      findRoleIds(e, id, value) {
        e.forEach(item => {
          if (item.value === id) {
            value["id"] = item.value
            value["codes"] = item.data.roleCodes
          } else if (item.children && item.children.length > 0) {
            this.findRoleIds(item.children, id, value)
          }
        })
      },
      findRoleCodes(e, code, value) {
        e.forEach(item => {
          if (item.data.roleCode === code) {
            value.push(item.value)
          } else if (item.children && item.children.length > 0) {
            this.findRoleCodes(item.children, code, value)
          }
        })
      },
      //关闭
      closeAlert() {
        this.formType = false;
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
      },
      //表单提交
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信息
      addUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        params["userRole"] = params.userRole[params.userRole.length - 1]
        this.$axios.Post(this.$api.userAdd, params).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.userModify, {
          "userId": this.form.id,
          "phone": this.form.userPhone,
          "userLogin": this.form.userLogin,
          "userName": this.form.userName,
          "userRole": this.form.userRole[this.form.userRole.length - 1]
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //删除表格数据
      delRow: function(row) {
        this.$confirm('确认要删除该条信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.Post(this.$api.userRemove, {
            "userId": row.id
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getData(this.searchForm.page, this.searchForm.limit)
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //修改用户状态
      changeState: function(e) {
        this.$axios.Post(this.$api.userEnable, {
          "userId": e.id,
          "enable": e.enable
        }).then(() => {}).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          e.enable = !e.enable
        })
      },
      //获取角色
      getRoleTree() {
        this.$axios.Get(this.$api.roleTree, {}).then(res => {
          let data = res.data
          this.formatRoleTree(data)
          this.roleOptions = data
        })
      },
      //格式化
      formatRoleTree(data) {
        data.forEach(item => {
          item["value"] = item.data.roleId
          if (item.children.length > 0) {
            this.formatRoleTree(item.children)
          } else {
            delete item.children
          }
        })
      }
    },
    components: {
      "table-page": () => import('@/components/TablePage')
    }
  }
</script>

<style lang="less">
  .role-node {
    width: 320px;
  }

  .role-list {
    margin-left: 332px;
    position: relative;
    padding: 10px;

    .content {
      width: 100%;
    }
  }

  .el-table .cell {
    line-height: inherit;
  }
</style>
